import { Link, } from "react-router-dom";
import { client } from "../libs/client";
import { useState, useEffect } from "react";
import { HandlLogin } from "../libs/functions"
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Sections from "../components/section";

function Home() {
  const [logined, setLogined] = useState<boolean | null>(client.logined);  
  useEffect(() => {
    client.on("ready", (status) => {
      console.log(status);
      setLogined(status);
    });
    if(!client.logined) client.login();
  }, []);

  return (
       <>
      <div className="bg-cover bg-no-repeat bg-center bg-gradient-to-b from-gray-900 to-gray-600">
        <Navbar />
        <section className="text-white text-center py-16 px-4">
          <div className="max-w-2xl mx-auto">
            <div className="bg-gray-800 text-purple-400 text-sm px-3 py-1 inline-block rounded-full mb-4 staggered-animation staggered-delay-1">
              New: Discord Music Player Integration
            </div>
            <h1 className="text-3xl sm:text-4xl font-semibold mb-4 staggered-animation staggered-delay-2">
              Enhance Your Discord with a Powerful Music Player!
            </h1>
            <p className="text-gray-400 mb-8 staggered-animation staggered-delay-3">
              Our Discord web music player supports Spotify, Deezer, Apple Music, and more. Play music directly from your Discord account and take control of your server’s vibe!
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4 staggered-animation staggered-delay-3">
              <a href={client.support_discord} target="_blank" rel="noopener noreferrer" className="bg-blue-800 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-md">
                Support
              </a>
              {logined == true && <Link to="/dashboard" className="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-3 px-6 rounded-md">
                Dashboard
              </Link>}
              {logined == null && <button className="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-3 px-6 rounded-md">
                Loading...
              </button>}
              {logined == false && <button onClick={HandlLogin} className="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-3 px-6 rounded-md">
                Login
              </button>}

            </div>
          </div>
        </section>
        <Sections/>
        <Footer />
      </div>
      </>
    
  )
}

export default Home;