import { Link } from "react-router-dom";
import { self } from "../types/Selfs";
import { formatTimestamp, statusClasses } from "../libs/functions";
import { client } from "../libs/client";


function SelfsCareds(selfs: self[]){
return(
   <>
                    
                    {selfs.map((c) => (
                        <div className="w-72 bg-gray-800 text-white rounded-lg overflow-hidden shadow-lg font-sans">
                            <div className="relative">
                                <svg className="w-full h-16" viewBox="0 0 340 60" style={{ minWidth: '340px', minHeight: '60px' }}>
                                    <mask id="maskId">
                                        <rect fill="white" x="0" y="0" width="100%" height="100%"></rect>
                                        <circle fill="black" cx="62" cy="56" r="46"></circle>
                                    </mask>
                                    
                                    <foreignObject x="0" y="0" width="100%" height="100%" overflow="visible" mask="url(#maskId)">
                                         <div style={{backgroundColor: c.color ?? "#000000"}} className="h-full"></div> 
                                         {/*<div className="h-full" style={{backgroundImage: "url('https://cdn.discordapp.com/banners/810540985032900648/42e3687e79623dedaea4eefd263e6c5d.png?size=480&quot')", backgroundSize: "cover"}}></div>*/}
                                    </foreignObject>
                                </svg>
                            </div>
                            <div className="relative -mt-20">
                                <div style={{marginLeft: "22px"}} className="relative inline-block">
                                    <img style={{ marginTop: "36px", }} src={c.avatar} alt="Avatar" className="w-20 h-20 rounded-full" />
                                    <div style={{ transform: 'translateX(380%)' }} className={`absolute bottom-1 right-21 w-4 h-4 rounded-full ${statusClasses(c.status)}`}></div>
                                </div>
                            </div>
                            <div className="p-5">
                                <div className="flex items-center justify-between mb-3">
                                    <h2 className="text-xl m-0">{c.displayname}</h2>
                                </div>
                                
                                    <>
                                        <p className="font-bold text-sm m-0">ABOUT ME</p>
                                        <div className="mb-3">
                                            <p className="text-sm m-0">
                                                {c.bio || "No bio available."}
                                            </p>
                                        </div>
                                    </>
                                
                                <div className="text-xs">
                                    <div className="mb-2">
                                        <p className="font-bold m-0">MEMBER SINCE</p>
                                        <span className="block text-gray-400">{formatTimestamp(c.createdAt)}</span>
                                    </div>
                                </div>
                                <div className="mt-4 text-center flex justify-between gap-2">
                                <Link to={`/dashboard/${c.id}/manage`}>
                                    <button className="bg-gray-700 text-white font-semibold py-2 px-4 rounded-md border border-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50" >
                                        Manage
                                    </button>
                                    </Link>
                                    <Link to={`/dashboard/${c.id}`}>
                                    <button className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-md border border-blue-700 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"  >
                                        Music Player
                                    </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
)
}

export default SelfsCareds