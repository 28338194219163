import { client } from "../libs/client";
import { useState, useEffect } from "react";
import OupsNotLogined from "../components/OupsNotLogined";
import  _selfs  from "../libs/selfs";
import { self } from "../types/Selfs";
import LoadingCard from "../components/loadingCateds";
import Navbar from "../components/navbar";
import SelfsCareds from "../components/selfsCards";

function Dashboard() {
    const [logined, setLogined] = useState<boolean | null>(client.logined);
    const [selfs, setselfs] = useState<{selfs: self[], loaded: null|boolean}>({selfs: _selfs.selfs, loaded: _selfs.loaded})
    
    useEffect(() => {
        client.on("ready", (status) => {
            setLogined(status);
           if(status) _selfs.load()
        });
        if(!client.logined){
            client.login()
        } else {
            _selfs.load()
        }
        _selfs.on("load", (cl) => {
            setselfs({selfs: cl, loaded: true});
        });
    }, []);
    return (
        <div className="bg-no-repeat bg-center bg-gradient-to-b from-gray-900 to-gray-600">
        {logined === false && 
        <div className="h-screen">
        <OupsNotLogined />
        </div>
        }
            {logined === true &&
           <div className="bg-cover bg-center h-screen flex flex-col">
            <Navbar/>
            <div className="flex flex-wrap justify-center items-center h-full gap-4">
            {selfs.loaded === null && <LoadingCard />}
            {logined === true && selfs.loaded === true && selfs.selfs.length > 0 &&
             SelfsCareds(selfs.selfs)
            }
            {logined === true && selfs.loaded === true && selfs.selfs.length === 0 && <> <h1 className="text-center text-2xl font-bold text-red-500 mt-10">No Selfs Found</h1></>}
            </div>
            </div>
            }
        </div>
    )
}

export default Dashboard;