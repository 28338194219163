import { useState } from "react";
import { client } from "../libs/client";

const Test = () => {  
  const [tokens, setTokens] = useState([
    { name: 'Namor', owner: 'Ra7eemi', expiresIn: 8, status: 'resumed' as 'resumed' | 'paused', tier: 'Tier 1' as 'Tier 1' | 'Tier 2' },
  ]);
  const [showForm, setShowForm] = useState(false);
  const [editingToken, setEditingToken] = useState<{ ownerId: string; token: string; expiresAt: string; tier: 'Tier 1' | 'Tier 2' } | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleAddNew = () => {
    setEditingToken(null);
    setShowForm(true);
  };

  const handleEditToken = (token: any) => {
    setEditingToken(token);
    setShowForm(true);
  };

  const handleSaveToken = (ownerId: string, token: string, expiresAt: string, tier: 'Tier 1' | 'Tier 2') => {
    if (editingToken) {
      // Update existing token logic
      setTokens(tokens.map((t) => (t.owner === editingToken.ownerId ? { ...t, owner: ownerId, expiresAt, tier } : t)));
    } else {
      // Add new token logic
      setTokens([...tokens, { name: 'Namor', owner: ownerId, expiresIn: 8, status: 'resumed', tier }]);
    }
    setShowForm(false);
  };

  const handleDeleteToken = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="p-4 sm:p-8 bg-[#2f3136] min-h-screen">
      <button onClick={handleAddNew} className="bg-[#7289da] hover:bg-[#5b6eae] text-white px-3 py-1 sm:px-4 sm:py-2 rounded mb-4 text-xs sm:text-sm">+ Add new</button>
      
      <div className="space-y-2 sm:space-y-4">
        {tokens.map((token, index) => (
          <TokenCard
            key={index}
            name={token.name}
            owner={token.owner}
            expiresIn={token.expiresIn}
            status={token.status}
            tier={token.tier} // Pass tier to TokenCard
            onPause={() => {}}
            onResume={() => {}}
            onDelete={() => setShowDeleteConfirmation(true)}
            onEdit={() => handleEditToken(token)}
          />
        ))}
      </div>

      {showForm && (
        <TokenForm 
          onSave={handleSaveToken} 
          onCancel={() => setShowForm(false)} 
          initialData={editingToken || undefined} 
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal 
          onConfirm={handleDeleteToken} 
          onCancel={() => setShowDeleteConfirmation(false)} 
        />
      )}
    </div>
  );
};

export default Test;
interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#36393f] p-6 rounded-lg shadow-md border border-[#202225]">
        <p className="mb-4 text-white">Are you sure you want to delete this token?</p>
        <div className="flex justify-end space-x-2">
          <button onClick={onCancel} className="bg-[#f04747] hover:bg-[#c73434] text-white px-4 py-2 rounded">Cancel</button>
          <button onClick={onConfirm} className="bg-[#43b581] hover:bg-[#3a8d67] text-white px-4 py-2 rounded">Yes</button>
        </div>
      </div>
    </div>
  );
};

interface TokenFormProps {
  onSave: (ownerId: string, token: string, expiresAt: string, tier: 'Tier 1' | 'Tier 2') => void;
  onCancel: () => void;
  initialData?: { ownerId: string; token: string; expiresAt: string; tier: 'Tier 1' | 'Tier 2' }|null;
}

const TokenForm: React.FC<TokenFormProps> = ({ onSave, onCancel, initialData }) => {
  const [ownerId, setOwnerId] = useState(initialData?.ownerId || '');
  const [token, setToken] = useState(initialData?.token || '');
  const [expiresAt, setExpiresAt] = useState(initialData?.expiresAt || '3DAYS');
  const [tier, setTier] = useState<'Tier 1' | 'Tier 2'>(initialData?.tier || 'Tier 1');
  const [error, setError] = useState({ ownerId: false, token: false });

  const handleSave = () => {
    if (!ownerId || !token) {
      setError({ ownerId: !ownerId, token: !token });
      return;
    }
    setError({ ownerId: false, token: false });
    onSave(ownerId, token, expiresAt, tier);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
      <div className="bg-[#36393f] p-6 rounded-lg shadow-lg w-full max-w-md border border-[#202225] text-white">
        <h2 className="text-lg font-bold mb-4 text-center">{initialData ? 'Edit Selftoun' : 'Add new Selftoun'}</h2>
        
        <div className="mb-4">
          <label className="block mb-1 text-gray-400 text-sm">Owner ID:</label>
          <input 
            type="text" 
            value={ownerId} 
            onChange={(e) => setOwnerId(e.target.value)} 
            className={`w-full p-2 bg-[#2f3136] border ${error.ownerId ? 'border-red-500' : 'border-gray-600'} rounded text-white`}
            placeholder="Enter Owner ID"
          />
          {error.ownerId && <p className="text-red-500 text-xs mt-1">Owner ID is required</p>}
        </div>
        
        <div className="mb-4">
          <label className="block mb-1 text-gray-400 text-sm">Token:</label>
          <input 
            type="text" 
            value={token} 
            onChange={(e) => setToken(e.target.value)} 
            className={`w-full p-2 bg-[#2f3136] border ${error.token ? 'border-red-500' : 'border-gray-600'} rounded text-white`}
            placeholder="Enter Token"
          />
          {error.token && <p className="text-red-500 text-xs mt-1">Token is required</p>}
        </div>
        
        <div className="mb-4">
          <label className="block mb-1 text-gray-400 text-sm">Expires at:</label>
          <select 
            value={expiresAt} 
            onChange={(e) => setExpiresAt(e.target.value)} 
            className="w-full p-2 bg-[#2f3136] border border-gray-600 rounded text-white"
          >
            <option value="3DAYS">Test 3DAYS</option>
            <option value="1WEEK">1 WEEK</option>
            <option value="1MONTH">1 MONTH</option>
            <option value="1YEAR">1 YEAR</option>
            <option value="LIFETIME">Lifetime</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-gray-400 text-sm">Tier:</label>
          <select 
            value={tier} 
            onChange={(e) => setTier(e.target.value as 'Tier 1' | 'Tier 2')} 
            className="w-full p-2 bg-[#2f3136] border border-gray-600 rounded text-white"
          >
            <option value="Tier 1">Tier 1</option>
            <option value="Tier 2">Tier 2</option>
          </select>
        </div>
        
        <div className="flex justify-end space-x-2">
          <button onClick={onCancel} className="bg-[#f04747] hover:bg-[#c73434] px-4 py-2 rounded text-white">Cancel</button>
          <button onClick={handleSave} className="bg-[#43b581] hover:bg-[#3a8d67] px-4 py-2 rounded text-white">Save</button>
        </div>
      </div>
    </div>
  );
};


interface TokenCardProps {
  name: string;
  owner: string;
  expiresIn: number;
  status: 'resumed' | 'paused';
  tier: 'Tier 1' | 'Tier 2'; // Include the tier in the props
  onPause: () => void;
  onResume: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

const TokenCard: React.FC<TokenCardProps> = ({ name, owner, expiresIn, status, tier, onPause, onResume, onDelete, onEdit }) => {
  return (
    <div className="bg-[#2c2f33] p-4 rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 border border-[#23272a] w-full text-white hover:shadow-lg transition-shadow duration-300">
      {/* Token Details */}
      <div className="flex items-center space-x-4 w-full md:w-auto">
        <div className="w-10 h-10 bg-[#7289da] rounded-full flex items-center justify-center text-white font-bold text-sm">
          {name.charAt(0)}
        </div>
        <div>
          <h4 className="font-bold text-white text-sm md:text-base">{name}</h4>
          <p className="text-xs text-gray-400">Owner: {owner}</p>
          <p className="text-xs text-gray-400">Expires in {expiresIn} days</p>
          <p className="text-xs text-blue-400 font-semibold">Tier: {tier}</p> {/* Display the tier */}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex items-center space-x-2 w-full md:w-auto justify-between md:justify-end text-xs md:text-sm">
        <button
          className={`px-3 py-1 rounded text-white flex items-center space-x-1 ${
            status === 'resumed' ? 'bg-[#43b581] hover:bg-[#3a8d67]' : 'bg-[#faa61a] hover:bg-[#e29110]'
          }`}
          onClick={status === 'resumed' ? onPause : onResume}
        >
          <i className={`fa ${status === 'resumed' ? 'fa-pause' : 'fa-play'}`} />
          <span>{status === 'resumed' ? 'Pause' : 'Resume'}</span>
        </button>
        <button
          onClick={onEdit}
          className="bg-[#7289da] hover:bg-[#5b6eae] px-3 py-1 rounded text-white flex items-center space-x-1"
        >
          <i className="fa fa-edit" />
          <span>Edit</span>
        </button>
        <button
          onClick={onDelete}
          className="bg-[#f04747] hover:bg-[#c73434] px-3 py-1 rounded text-white flex items-center space-x-1"
        >
          <i className="fa fa-trash" />
          <span>Delete</span>
        </button>
      </div>
    </div>
  );
};