import { useEffect, useState } from "react";
import { client } from "../libs/client";
import OupsNotLogined from "../components/OupsNotLogined";
import _selfs from "../libs/selfs";
import { self } from "../types/Selfs";
import { useParams } from "react-router-dom";
import Navbar from "../components/navbar";
import { guilds } from "../libs/guilds";
import { GuildsProps } from "../types/Guilds";
import Error from "../components/Error";
import GuildsIcones from "../components/guilds";
import Sidebar_Items from "../components/sidebar_items";
import ToggleSwitch from "../components/toggleSwitch";


function Manage() {
  let { id } = useParams();
  const [logined, setLogined] = useState<boolean | null>(client.logined);
  const [Guilds, setguilds] = useState<{ guilds: GuildsProps[], loaded: boolean | null }>({ guilds: guilds.guilds, loaded: guilds.loaded })
  const [selfs, setselfs] = useState<{ selfs: self[], loaded: null | boolean }>({ selfs: _selfs.selfs, loaded: null })
  useEffect(() => {
    client.on("ready", (status) => {
      setLogined(status);
      if (status) _selfs.load()
    });
    if (!client.logined) {
      client.login()
    } else {
      if (!_selfs.loaded) _selfs.load()
    }
    _selfs.on("load", (Selfs) => {
      setselfs({ loaded: _selfs.loaded, selfs: Selfs })
      if (_selfs.loaded) {
        if (!guilds.loaded) guilds.fetch(id as string)
      }
    });
    guilds.on("guilds", (new_guilds) => {
      setguilds({ guilds: new_guilds, loaded: guilds.loaded });
    });
    if (_selfs.loaded) guilds.fetch(id as string)
  }, []);

  return (
    <div className="bg-no-repeat bg-center bg-gradient-to-b from-gray-900 to-gray-600">
      <div className="h-screen">
        {logined === false &&
          <OupsNotLogined />
        }

        {logined === true && <>
          {Guilds.loaded == false && <>

            <Error herf="/dashboard" message="Something went wrong while loading guilds." />
          </>}
          {Guilds.loaded == true &&
            <div className="h-screen flex flex-col ">
              <Navbar />
              <div className="flex flex-row h-full overflow-hidden">

                {logined == true &&
                  <>

                    <GuildsIcones self={selfs.selfs.find(x => x.id == id)!} activeId={null} guilds={Guilds.guilds} id={id!}></GuildsIcones>
                    <div id="voices" className="w-[240px] bg-[#2A2D31] h-full overflow-y-auto flex-shrink-0 " >
                      <div className="flex flex-col items-center">
                        <img src={selfs.selfs.find(x => x.id == id)!.avatar} alt="avatar" className="w-[70px] h-[70px] rounded-full mt-4" />
                        <h1 className="text-white text-xl font-bold mt-4">{selfs.selfs.find(x => x.id == id)!.username}</h1>
                      </div>
                      <Sidebar_Items active={"manage"} isOpen={true} self={selfs.selfs.find(x => x.id == id)!} />
                    </div>

                    <div className="w-full h-full flex overflow-y-auto ">
                      <div className="flex flex-col w-full p-[16px]">
                        <h1 className="text-white text-2xl ">Manage</h1>
                        <div className="w-full h-[0.4px] bg-white mt-2"></div>
                        <div className="flex flex-col h-full p-4">
                          <div className="p-4">
                              {/* set a enable or disable Automatic follow */}
                              {/*  */}
                              <ToggleSwitch disabledGuilds={true} enableGuilds={true} defaultselected={{
                                enabled: selfs.selfs.find(x => x.id == id)!.data.join_enabled,
                                disabled: selfs.selfs.find(x => x.id == id)!.data.join_disabled
                              }} onStatusEdited={(status) => {
                                _selfs.save(id, {auto_join: status})
                              }} onSave={(data) => {
                                _selfs.save(id, {join_enabled: data.enabled, join_disabled: data.disabled})
                              }} status={selfs.selfs.find(x => x.id == id)!.data.auto_join} id={id as string} label="Auto Following In Join" guilds={Guilds.guilds} description="If You Joined Voice Channel i Will Join You Channel" />
                              {/*  */}
                              <ToggleSwitch disabledGuilds={true} enableGuilds={true} defaultselected={{
                                enabled: selfs.selfs.find(x => x.id == id)!.data.left_enabled,
                                disabled: selfs.selfs.find(x => x.id == id)!.data.left_disabled
                              }} onStatusEdited={(status) => {
                                _selfs.save(id,  {auto_left: status})
                              }} onSave={(data) => {
                                _selfs.save(id, {left_enabled: data.enabled, left_disabled: data.disabled})
                              }} status={selfs.selfs.find(x => x.id == id)!.data.auto_left} id={id as string} label="Auto Following In Disconnect" guilds={Guilds.guilds} description="If You Disconnect Voice Channel i Will Left Channel" />
                              
                                {/*  */}
                                <ToggleSwitch disabledGuilds={false} enableGuilds={false} defaultselected={{
                                audio: selfs.selfs.find(x => x.id == id)!.data.audio
                              }}
                              audioStatus={true}
                              onStatusEdited={(status) => {
                                _selfs.save(id,  {audio_status: status})
                              }} onSave={(data) => {
                                _selfs.save(id, {audio: data.audio})

                                console.log(data)
                              }} status={selfs.selfs.find(x => x.id == id)!.data.audio_status} id={id as string} label="Auto Make Sound" guilds={Guilds.guilds} description="If I Joined Voice Channel i Will Make Sound" />
                              
                          </div>
                        </div>
                      </div>
                    </div>

                  </>
                }
              </div>
            </div>
          }
        </>
        }
      </div>
    </div>
  )
}


export default Manage;