import axios from "axios";
import { EventEmitter } from "events";
const token = localStorage.getItem("token");

const API_URL = token == ""/* "F603-E0f69f0cc-R4e6eR-Ycdd" */ ? "https://testapi.selftouns.shop/api":"https://api.selftouns.shop/api";
const SOCKET_URL = token == ""/* "F603-E0f69f0cc-R4e6eR-Ycdd" */ ? "https://testapi.selftouns.shop" : "https://api.selftouns.shop";
class Client extends EventEmitter {
    support_discord: string = "https://discord.gg/aFBwQE6HjM"
    API_URL: string = API_URL;
    SOCKET_URL: string = SOCKET_URL;
    logined: boolean | null;
    id: string | null;
    username: string | null;
    admin: boolean | null;
    avatar: string | null;
    token: string | null;
    socket: any;
    defaultAvatar: string = "https://cdn.discordapp.com/embed/avatars/0.png";

    constructor() {
        super();
        this.token = this.getToken();
        this.logined = null;
        this.id = null;
        this.username = null;
        this.avatar = null;
        this.socket = null;
        this.admin = null;  
    }

    avatarURL(): string {
        if (!this.avatar) {
            return this.defaultAvatar;
        }
        return `https://cdn.discordapp.com/avatars/${this.id}/${this.avatar}.${this.avatar.startsWith("a_") ? "gif" : "png"}?size=1024`;
    }
    logout(): void {
        this.token = null;
        window.localStorage.removeItem("token");
        this.logined = false;
        this.id = null;
        this.username = null;
        this.avatar = null;
        this.socket = null;
        this.admin = null;
        this.emit("ready", false);
    }
    getToken(): string | null {
        const token = localStorage.getItem("token");
        if (token) {
            this.token = token;
            return token;
        }
        return null;
    }

    async login(): Promise<void> {
        this.token = this.getToken();
        if (this.token) {
            const status = await this.getData();
            if (!status) {
                this.token = null;
            } else {
                window.localStorage.setItem("token", this.token);
            }
            this.emit("ready", status);
        } else {
            this.token = null;
            this.logined = false;
            this.emit("ready", false);
        }
    }

    async getData(): Promise<boolean> {
        console.log("starting get data from server");
        
        this.getToken();
        if (this.token) {
            try {
                const response = await axios.get(`${API_URL}/user`, {
                    headers: {
                        Authorization: `${this.token}`
                    }
                });
                if (response.status === 200) {
                    const userdata: DataUser = response.data.data;
                    this.id = userdata.id;
                    this.username = userdata.username;
                    this.avatar = userdata.avatar;
                    this.logined = true;
                    this.admin = userdata.admin ?? false;
                    return true;
                }
            } catch (error) {
                this.token = null;
                this.logined = false;
                return false;
            }
        }
        return false;
    }
}

interface ClientEvents {
    ready: (logined: boolean) => void;
}

interface DataUser {
    token: string | null
    id: string,
    username: string,
    access_token: string,
    refresh_token: string,
    scope: string,
    avatar: string,
    admin: boolean|null,
    token_type: string,
}

declare interface Client {
    on<U extends keyof ClientEvents>(event: U, listener: ClientEvents[U]): this;
    emit<U extends keyof ClientEvents>(event: U, ...args: Parameters<ClientEvents[U]>): boolean;
}

export let client = new Client();