import EventEmitter from "events";
import { client } from "./client";
import axios from "axios";
import { data, self } from "../types/Selfs";
import { toast } from "react-toastify";
class Selfs extends EventEmitter {
    loaded: boolean | null;
    selfs: self[];
    constructor() {
        super();
        this.loaded = null;
        this.selfs = [];
    }
    async load() {
        if (!client.token) return;
        const req = await axios.get(`${client.API_URL}/selfs`, {
            headers: {
                Authorization: `${client.token}`
            }
        });
        if (req.data.ok) {
            this.selfs = req.data.bots;
            this.loaded = true;

        } else {
            this.loaded = false;
            this.selfs = [];
        }
        console.log(this.selfs);
        this.emit("load", this.selfs);
    }
    async save(selfId: string | undefined, {auto_join, auto_left, join_disabled, join_enabled, left_disabled, left_enabled, audio, audio_status}: data ) {
        if (!client.token) return;
        const self = this.selfs.find(x => x.id == selfId);
        if (!self) return
        const req = await axios.put(`${client.API_URL}/selfs/${selfId}`, {
            auto_join,
            auto_left,
            join_disabled,
            join_enabled,
            left_disabled,
            left_enabled,
            audio, 
            audio_status,
        }, {
            headers: {
                Authorization: `${client.token}`
            }
        });
        if (req.data.ok) {
            this.load()

          if(auto_join == undefined && auto_left == undefined && audio_status == undefined) toast.success("Saved successfully");
        } else {
            toast.error("Error saving");
        }
    }
}
const _selfs = new Selfs();
export default _selfs;

interface selfsEvents {
    load: (selfs: self[]) => void;
}
declare interface Selfs {
    on<U extends keyof selfsEvents>(
        event: U, listener: selfsEvents[U]
    ): this;

    emit<U extends keyof selfsEvents>(
        event: U, ...args: Parameters<selfsEvents[U]>
    ): boolean;
}
