import { useEffect, useState } from "react";
import logo from "../images/logo512.png"
import { client } from "../libs/client";
import { HandlLogin } from "../libs/functions";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate();
    const [logined, setLogined] = useState<boolean | null>(client.logined);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    useEffect(() => {
        client.on("ready", (status) => {
            console.log(status);
            setLogined(status);
        });
    }, [client]);
    function Loginout(){
        client.logout()
        navigate("/")
    }
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleOutsideClick = (event: any) => {
        if (!event.target.closest('.dropdown-container')) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <nav className="bg-gray-900 text-white p-4 flex justify-between items-center ">
            <Link to={"/"}>
            <div className="flex items-center">
                <img src={logo} alt="Logo" className="w-10 h-10 mr-3" />
                <span className="text-xl font-semibold text-blue-800">SelfTouns</span>
            </div>
            </Link>
            <div className="flex items-center space-x-6">
                {logined === false && (
                    <button
                        onClick={HandlLogin}
                        className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-md border border-blue-700 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    > Login</button>
                )}
                {logined === null && (
                    <span className="text-gray-400">Loading...</span>
                )}
                {logined === true && (
                      <div className="relative dropdown-container">
                      <img
                          src={client.avatarURL()}
                          alt="User Avatar"
                          className="w-10 h-10 rounded-full border-2 border-gray-700 hover:border-gray-500 cursor-pointer"
                          onClick={toggleDropdown}
                      />
                      {dropdownOpen && (
                          <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-lg z-50">
                              <ul className="text-sm text-white">
                                  <li onClick={() => navigate("/dashboard")} className=" px-4 py-2 hover:bg-gray-700 cursor-pointer">Dashboard</li>
                                  { client.admin && <li onClick={() => navigate("/admin")} className="px-4 py-2 hover:bg-gray-700 cursor-pointer">Admin</li>}
                                  <li onClick={() => {window.open(client.support_discord)}} className="px-4 py-2 hover:bg-gray-700 cursor-pointer">Support</li>
                                  <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer">Docs</li>
                                  <li className="border-t border-gray-700 px-4 py-2 hover:bg-red-600 cursor-pointer text-red-400 hover:text-white"><button onClick={() => {Loginout()}}>Logout</button></li>
                              </ul>
                          </div>
                      )}
                  </div>
              )}
            </div>
        </nav>
    );
}

export default Navbar;