import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { client } from "../libs/client";
import { toast } from 'react-toastify';

function Login() {
    const { token } = useParams();
    const navigate = useNavigate();
    let close: boolean = false
    useEffect(() => {

        localStorage.setItem('token', `${token}`);
        client.getData()
            .then((res: boolean) => {
                if (res) {
                    if (window.opener && window.opener.location && window.opener.location.hostname === window.location.hostname) {
                        window.close();
                    }
                    navigate('/dashboard');
                } else {
                    navigate('/');
                }
            });
    }, [token, navigate]);
    return (
        <div>
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
            </div>
        </div>
    )
}

export default Login;