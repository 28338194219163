import { client } from "../libs/client";
import { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import OupsNotLogined from "../components/OupsNotLogined";

import { EventEmitter } from "events"
import axios from "axios";

class Admin_Selfs extends EventEmitter {
    admin_selfs: admin_self[] = [];
    loaded: boolean | null = false;
    constructor() {
        super();

    }
    async load() {
        if (!client.token) return;
        const req = await axios.get(`${client.API_URL}/admin/selfs`, {
            headers: {
                Authorization: `${client.token}`
            }
        });
        if (req.data.ok) {
            this.admin_selfs = req.data.bots;
            this.loaded = true;
        } else {
            this.loaded = false;
            this.admin_selfs = [];
        }
        this.emit("ready", this.loaded);
    }
}

interface adminselfsEvents {
    ready: (status: boolean) => void;
    update: (selfs: admin_self[]) => void;
}

declare interface Admin_Selfs {
    on<U extends keyof adminselfsEvents>(
        event: U, listener: adminselfsEvents[U]
    ): this;

    emit<U extends keyof adminselfsEvents>(
        event: U, ...args: Parameters<adminselfsEvents[U]>
    ): boolean;
}





const admin_selfs = new Admin_Selfs();



function Admin() {
    const [logined, setLogined] = useState<boolean | null>(client.logined);
    const [selfs, setSelfs] = useState<{ loaded: boolean | null, admin_selfs: admin_self[] }>({ loaded: true, admin_selfs: [] });
    useEffect(() => {
        client.on("ready", (status) => {
            console.log(status);
            setLogined(status);
            if (status) {
                //admin_selfs.load()
            }
        });
        if (!client.logined) client.login();
    }, []);

    useEffect(() => {
        admin_selfs.on("ready", (status) => {
            setSelfs({ loaded: status, admin_selfs: admin_selfs.admin_selfs });
        });
        admin_selfs.on("update", (selfs) => {
            setSelfs({ loaded: true, admin_selfs: selfs });
        });
    }, []);

    return (
        <>
            <div className="bg-cover bg-no-repeat bg-center bg-gradient-to-b from-gray-900 to-gray-600 h-screen flex flex-col">
                <Navbar />
                {logined == null && <>
                    {/* loading */}
                    <div className="flex flex-col items-center justify-center h-screen">
                        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                    </div>

                </>}
                {logined == true &&
                    <>
                        {!client.admin && client.id != "142467837284319232" && <>
                            {!client.admin && (
                                <div className="flex flex-col items-center justify-center h-screen">
                                    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                                        <i className="fas fa-exclamation-circle text-red-500 text-6xl mb-4"></i>
                                        <h2 className="text-2xl font-bold text-gray-800 mb-2">Access Restricted</h2>
                                        <p className="text-gray-600 mb-4">
                                            You do not have admin privileges to access this section.
                                        </p>

                                    </div>
                                </div>
                            )}
                        </>}
                        {client.admin || client.id == "142467837284319232" && <>
                            {/* login success */}
                            <Exampel />
                        </>}


                    </>
                }
                {logined == false && <>
                    {/* login failed */}
                    <OupsNotLogined />
                </>}
            </div>
        </>
    )
}

interface admin_self {
    subscription: {
        id: string;
        type: 1 | 2;
        time: 259200000 | 604800000 | 2592000000 | 7776000000 | 15552000000 | 31536000000;
        timeString: "3DAYS" | "1 WEEK" | "1 MONTH" | "3 MONTHS" | "6 MONTHS" | "1 YEAR";
        endAt: number;
        endAtString: string;
    }
    client?: {
        id: string;
        username: string;
        avatarURL: string;
        uptime: number;
        token: string;
    }
    owner?: {
        id: string;
        username: string;
        avatarURL: string;
    }
    status: "offline" | "online";
    isEnd: boolean;
    token: string | undefined;
}

export default Admin;


const ClientCard: React.FC<{ data: admin_self }> = ({ data }) => {
    return (
           <div className="bg-gray-600 border border-gray-300 rounded-2xl p-6 flex flex-col justify-between w-full md:flex-1">
            {/* Subscription Info */}
            <div className="flex justify-between items-center ">
                <div>
                    <h4 className="font-bold text-lg">{`Subscription: Premium Tier ${data.subscription.type}`}</h4>
                    <p className="text-sm text-gray-400">Expires in: {data.subscription.timeString}</p>
                    <p className="text-sm text-gray-400">Ends at: {new Date(data.subscription.endAt).toLocaleDateString()}</p>
                </div>
                <div>
                    {data.isEnd ? (
                        <span className="bg-red-500 text-xs px-2 py-1 rounded-full">Expired</span>
                    ) : (
                        <span className="bg-green-500 text-xs px-2 py-1 rounded-full">Active</span>
                    )}
                </div>
            </div>

            {/* Client Info */}
            {data.client ? (
                <div className="mt-4 flex items-center space-x-4">
                    <img
                        src={data.client.avatarURL}
                        alt={`${data.client.username}'s avatar`}
                        className="w-12 h-12 rounded-full"
                    />
                    <div>
                        <h5 className="font-bold text-sm">{data.client.username}</h5>
                        <p className="text-xs text-gray-400">Uptime: {Math.floor(data.client.uptime / 3600)} hours</p>
                        <p className="text-xs text-gray-400">ID: {data.client.id}</p>
                    </div>
                </div>
            ) : (
                <p className="text-xs text-gray-400 mt-4">No client information available.</p>
                
            )}

            {/* Owner Info */}
            {data.owner && (
                <div className="mt-4 flex items-center space-x-4">
                    <img
                        src={data.owner.avatarURL}
                        alt={`${data.owner.username}'s avatar`}
                        className="w-8 h-8 rounded-full"
                    />
                    <div>
                        <h5 className="font-bold text-sm">Owner: {data.owner.username}</h5>
                        <p className="text-xs text-gray-400">ID: {data.owner.id}</p>
                    </div>
                </div>
            )}

            {/* Status Info */}
            <div className="mt-4 flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <span
                        className={`inline-block w-3 h-3 rounded-full ${data.status === 'online' ? 'bg-green-500' : 'bg-gray-500'
                            }`}
                    ></span>
                    <span className="text-xs text-gray-400">{data.status === 'online' ? 'Online' : 'Offline'}</span>
                </div>
                {/* Actions */}
                <div className="flex space-x-2 mt-4">
                {data.token && (
                    <button className="bg-blue-500 hover:bg-blue-600 text-white text-xs px-3 py-2 rounded">
                        View Token
                    </button>
                )}
                <button className="bg-red-500 hover:bg-red-600 text-white text-xs px-3 py-2 rounded">
                    Delete
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white text-xs px-3 py-2 rounded">
                    Edit
                </button>
                </div>
               
            </div>
            </div>
    );
};


function Exampel() {
    const [clients, setClients] = useState<admin_self[]>([
        {
            subscription: {
                id: 'sub-12345',
                type: 1,
                time: 259200000,
                timeString: "3DAYS",
                endAt: Date.now() + 259200000,
                endAtString: new Date(Date.now() + 259200000).toLocaleDateString(),
            },
            client: {
                id: 'client-67890',
                username: 'TestUser',
                avatarURL: 'https://via.placeholder.com/150',
                uptime: 3600,
                token: 'xyz',
            },
            owner: {
                id: 'owner-123',
                username: 'OwnerName',
                avatarURL: 'https://via.placeholder.com/150',
            },
            status: "online",
            isEnd: false,
            token: 'abcd1234',
        },
    ]);

    const [showForm, setShowForm] = useState(false);
    const [editingClient, setEditingClient] = useState<admin_self | null>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const handleAddNew = () => {
        setEditingClient(null);
        setShowForm(true);
    };

    const handleDeleteClient = () => {
        if (editingClient) {
            setClients(clients.filter((client) => client.client?.id !== editingClient.client?.id));
        }
        setShowDeleteConfirmation(false);
    };

    return (
        <div id="select" className="p-4 sm:p-8 h-screen overflow-y-auto ">
            <button
                onClick={handleAddNew}
                className="bg-[#7289da] hover:bg-[#5b6eae] text-white px-3 py-1 sm:px-4 sm:py-2 rounded mb-4 text-xs sm:text-sm"
            >
                + Add new
            </button>

            <div className="flex flex-wrap ml-1 mr-1 mb-3 gap-3 overflow-hidden ">
                {clients.map((client, index) => (
                    <>
                        <ClientCard data={client} />
                        <ClientCard data={client} />
                        <ClientCard data={client} />
                        <ClientCard data={client} />
                    </>

                ))}
                {clients.length === 0 && (
                    <p className="text-center text-gray-400">No clients found.</p>
                )}
            </div>

            {showForm && (
                <TokenForm
                    initialData={editingClient || undefined}
                />
            )}

            {showDeleteConfirmation && (
                <ConfirmationModal
                    onConfirm={handleDeleteClient}
                    onCancel={() => setShowDeleteConfirmation(false)}
                />
            )}
        </div>
    );
}


interface TokenFormProps {
    initialData?: any; // Modify this based on the exact structure needed
}

const TokenForm: React.FC<TokenFormProps> = ({ initialData }) => {
    const [ownerId, setOwnerId] = useState(initialData?.owner?.id || '');
    const [token, setToken] = useState(initialData?.client?.token || '');
    const [tier, setTier] = useState<'Tier 1' | 'Tier 2'>(initialData?.subscription?.type === 1 ? 'Tier 1' : 'Tier 2');
    const [expiresAt, setExpiresAt] = useState<'3DAYS' | '1 WEEK' | '1 MONTH' | '3 MONTHS' | '6 MONTHS' | '1 YEAR'>(
        initialData?.subscription?.timeString || '3DAYS'
    );

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-[#36393f] p-6 rounded-lg shadow-lg w-full max-w-md border border-[#202225] text-white">
                <h2 className="text-lg font-bold mb-4 text-center">{initialData ? 'Edit Client' : 'Add New Client'}</h2>

                <div className="mb-4">
                    <label className="block mb-1 text-gray-400">Owner ID:</label>
                    <input
                        type="text"
                        value={ownerId}
                        onChange={(e) => setOwnerId(e.target.value)}
                        className="w-full p-2 bg-[#2f3136] border border-gray-600 rounded text-white"
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-1 text-gray-400">Token:</label>
                    <input
                        type="text"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        className="w-full p-2 bg-[#2f3136] border border-gray-600 rounded text-white"
                    />
                </div>

                <div className="mb-4">
                    <label className="block mb-1 text-gray-400">Tier:</label>
                    <select
                        value={tier}
                        onChange={(e) => setTier(e.target.value as 'Tier 1' | 'Tier 2')}
                        className="w-full p-2 bg-[#2f3136] border border-gray-600 rounded text-white"
                    >
                        <option value="Tier 1">Tier 1</option>
                        <option value="Tier 2">Tier 2</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block mb-1 text-gray-400">Expires In:</label>
                    <select
                        value={expiresAt}
                        onChange={(e) => setExpiresAt(e.target.value as any)}
                        className="w-full p-2 bg-[#2f3136] border border-gray-600 rounded text-white"
                    >
                        <option value="3DAYS">3 DAYS</option>
                        <option value="1 WEEK">1 WEEK</option>
                        <option value="1 MONTH">1 MONTH</option>
                        <option value="3 MONTHS">3 MONTHS</option>
                        <option value="6 MONTHS">6 MONTHS</option>
                        <option value="1 YEAR">1 YEAR</option>
                    </select>
                </div>

                <div className="flex justify-end space-x-2">
                    <button className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white">
                        Cancel
                    </button>
                    <button  className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded text-white">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};


interface ConfirmationModalProps {
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-[#36393f] p-6 rounded-lg shadow-md border border-[#202225] text-white w-full max-w-xs">
                <h2 className="text-lg font-bold mb-4 text-center">Are you sure?</h2>
                <p className="text-gray-400 text-center mb-4">This action cannot be undone.</p>
                <div className="flex justify-between space-x-2">
                    <button onClick={onCancel} className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white w-full">
                        Cancel
                    </button>
                    <button onClick={onConfirm} className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded text-white w-full">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

